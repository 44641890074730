import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { BlockContent, Hero, Section, SEO, Button, Image } from "components"

const NewsTemplate = ({ data }) => {
  const { sanityNews, sanityLeaflet } = data

  const { title, image, day, month, year, _rawContent, leafletFile } =
    sanityNews || sanityLeaflet

  return (
    <>
      <SEO title={title} />

      <Hero title={title} image={image && image} overlay />

      <Section>
        {/* <Image className="shadow-soft" source={image} /> */}

        <div className="text-center mb-8">
          {/* <Heading className="text-center " level="1">
              {title}
            </Heading> */}
          {/* {day && (
            <div className="uppercase text-xs text-gray-500">
              Publicado dia {day} de {month} de {year}
            </div>
          )} */}
        </div>

        <div className="max-w-2xl mx-auto">
          {leafletFile && <Image className="mb-8" source={image} />}
          <BlockContent blocks={_rawContent} />

          {leafletFile && <Button to={leafletFile.asset.url}>Download</Button>}
        </div>
      </Section>
    </>
  )
}

NewsTemplate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
}

export default NewsTemplate

export const query = graphql`
  query GET_NEWS($slug: String) {
    sanityNews(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      _rawContent
      image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    sanityLeaflet(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      leafletFile {
        asset {
          title
          url
        }
      }
      _rawContent
      image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
